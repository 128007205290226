@import 'styles/mixins';

.slidingPanel {
  z-index: 9999;
  transform: translateY(100%);
  display: flex;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px;
  background-color: #fff;
  max-height: 40%;
  @include transition(0.4s, ease-in-out, 0s, transform);
}

.isVisibleSlidingPanel {
  transform: translateY(0);
}

.content {
  z-index: 999;
  width: 100%;
  padding: 20px;
  max-height: 100%;
  overflow: auto;
  background-color: #fff;
  position: relative;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 20px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%);
  }
}