@import 'styles/mixins';

.translator {}

.translatorWorkplace {
  display: flex;
}

.localesSelectContainer {
  display: flex;
}

.localeSelectBox {
  width: 50%;
  box-sizing: border-box;
  position: relative;
  &:nth-child(odd) {
    padding-right: 25px;
    &:after {
      content: '';
      position: absolute;
      top: 28px;
      right: -9px;
      background-image: url("./i/arrow-right.png");
      background-size: contain;
      width: 18px;
      height: 18px;
    }
  }
  &:nth-child(even) {
    padding-left: 25px;
  }
}

.actions {
  width: 10%;
  display: flex;
  align-items: center;
}
.translateButton {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-size: 0;
  text-align: center;
  > img {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 2px 9px rgba(0,0,0,.07);
    padding: 10px;
    box-sizing: border-box;
    @include transition(.2s, ease-in-out, 0s, box-shadow);
    &:hover {
      box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    }
  }
}
.isDisabledTranslateButton {
  pointer-events: none;
  opacity: .3;
}

.zone {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.label {
  height: 16px;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.labelIcon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.selectWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  > img {
    height: 36px;
    margin-right: 10px;
  }
}

.targetZoneContainer {
  flex: 1;
  position: relative;
}
.targetZone {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  white-space: pre-wrap;
  color: #555555;
  padding: 10px 5px;
  background-color: #efeeee;
  border-radius: 4px;
  box-sizing: border-box;
}