@import 'styles/mixins';

.tongue {
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 50px;
  height: 30px;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  box-shadow: rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  @include transition(.2s, ease-in-out, 0s, (transform, box-shadow));
  &:hover {
    box-shadow: rgba(0,0,0,.12) 0 2px 12px, rgba(0,0,0,.16) 0 2px 7px;
  }
}

.isActiveTongue {

}